<template>
  <v-card flat tile class="transparent" width="100%" height="100%">
      ActiveSession {{ActiveSession}}
      
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import MyActivitiesbyType from '@/views/Activities/MyProfile/MyActivitiesbyType'

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession'],
    components: {
        MyActivitiesbyType
    },
    data(){
        return{
        
        }
    },
    computed:{

    },
    created(){

    },
    methods:{

    }

}
</script>

<style>

</style>


    